import React from "react";
import "./App.css";
import Sidebar from "./components/Sidebar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WithUp from "./pages/WithUp";
import Soua from "./pages/Soua";
import Pavlov from "./pages/Pavlov";
import Minusation from "./pages/Minusation";
import Printables from "./pages/Printables";

function App() {
  return (
    <Router>
      <div className="container">
        <Sidebar />
        <div className="main">
          <Routes>
            <Route path="/" />
            <Route path="/WithUp" element={<WithUp />} />
            <Route path="/Soua" element={<Soua />} />
            <Route path="/Pavlov" element={<Pavlov />} />
            <Route path="/Minusation" element={<Minusation />} />
            <Route path="/Printables" element={<Printables />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
