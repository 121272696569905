import React from "react";
import { NavLink } from "react-router-dom";
import "../App.css";

function Sidebar() {
  return (
    <div className="sidebar">
      <p className="site-title-p1">Jiahao Chen</p>
      <p className="site-title-p2">Portfolio</p>
      <nav className="sidebar-nav">
        <ul>
          <li>
            <NavLink
              to="/WithUp"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              With Up
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Soua"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              SOUA
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Pavlov"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Pavlov
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Minusation"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Minusation
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Printables"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Printables
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
