// import { Carousel } from "@material-tailwind/react";
import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

// import required modules
// import { Navigation } from "swiper/modules";
import { FreeMode, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "../styles/Slide.css";
// import required modules

const Slider = ({ props, num }) => {
  // Import all images using require.context
  const totalImages = num; // Total number of images
  const images = [];
  for (let i = 1; i <= totalImages; i++) {
    images.push(`/images/${props}/${props}_${i}.jpg`);
    console.log(images);
  }

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={"auto"}
      freeMode={true}
      modules={[FreeMode, Pagination]}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image} alt={`Slide ${index + 1}`} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
